import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../Navbar'
import axios from 'axios';
import jwt_decode from "jwt-decode";

import baseUrl from "../../BaseUrl";
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../Header';
import Loader from '../../Loader';


const Step1 = ({ onNext,compid, role,token, formData, setFormData }) => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState([]);
    const [expire, setExpire] = useState('');
    const [cid, setCid] = useState(compid);
    const [user_id, setUserId] = useState('');
    const [access, setAccess] = useState([]);
    const [file, setFile] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
 
    const [validationErrors, setValidationErrors] = useState({
        cid: false,
        mapping_type: false,
        file: false
    });
    const [errorList, setErrorList] = useState([]);
    
    const errorListRef = useRef(null);
    
    const formRef = useRef(null);
    const fileInputRef = useRef(null)

   

    useEffect(() => {
        if (token) {
            getUserCompany();
        }
    }, [token]);


    const getUserCompany = async () => {

        const response = await axios.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        let allArray;
        if (role <= 2) {
            // allArray = response.data.slice(1);
            allArray = response.data.filter(item => item.id !== 1);
        } else {
            allArray = response.data;
        }

        setCompany(allArray);

        if(role > 2){
            console.log(allArray[0].id)
            const companyId = allArray[0].id
            setCid(companyId)
            setFormData((prevData) => ({
                ...prevData,
                cid: companyId,
            }));
        }
    }




    const handleInputChange = (e) => {
        const { name, value } = e.target;

        console.log(name, value)

        console.log(name, value)

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));


        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false
        }));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    // const handleImport  = async (e)=>{
    //     e.preventDefault();
    //     const newValidationErrors = {
    //         file:file == '',
    //         cid: formData.cid === '',
    //         mapping_type:formData.mapping_type === '0',
    //       };
         
    //       setValidationErrors(newValidationErrors);
    //       const hasErrors = Object.values(newValidationErrors).some((error) => error);
    //       console.log(hasErrors)
    //       if(!hasErrors){
    //         console.log(file)
    //         console.log(formData.cid,formData.mapping_type)
    //         const newData = new FormData();
    //             newData.append("file", file);               
    //             newData.append('company_id',formData.cid);
    //             newData.append('mapping_type',formData.mapping_type)
    //             // setPopupOpen(true);
    //             setPopupOpen(true);
    //             try {
    //                 const response = await axios.post(`${baseUrl}/digit-mapping/import`, newData,{
    //                     headers: {
    //                          Authorization: `Bearer ${token}`,
    //                          "Content-Type": "multipart/form-data",
    //                     }
    //                 });

    //                 const allArray = response.data;
    //                 console.log(allArray); 

    //                 setFile('')
    //                 const fileInput = fileInputRef.current
    //                 if(fileInput){
    //                     fileInput.value = ""
    //                     fileInput.type = 'text'
    //                     fileInput.type = 'file'
    //                 }

    //                 if(allArray.success){
    //                     navigate("/digit-mapping");
    //                 }else{
    //                     setErrorList(allArray)
    //                 }
    //             } catch (error) {
    //                 console.error(error)
    //             }
    //             setPopupOpen(false)
                

                
    //       }
    // }
    const handleNext = async (e) => {
        e.preventDefault();
        const newValidationErrors = {
            file:file == '',
            cid: formData.cid === '',
            mapping_type:formData.mapping_type === '0',
        };
         
        setValidationErrors(newValidationErrors);
        const hasErrors = Object.values(newValidationErrors).some((error) => error);
        console.log(hasErrors)
        if(!hasErrors){
            const newData = new FormData();
            newData.append("file", file);               
            newData.append('company_id',formData.cid);
            newData.append('mapping_type',formData.mapping_type)
            setPopupOpen(true);
            try {
                const response = await axios.post(`${baseUrl}/digit-mapping/import-validate`, newData,{
                    headers: {
                         Authorization: `Bearer ${token}`,
                         "Content-Type": "multipart/form-data",
                    }
                });

                const result = response.data;

                setFile('')
                const fileInput = fileInputRef.current
                if(fileInput){
                    fileInput.value = ""
                    fileInput.type = 'text'
                    fileInput.type = 'file'
                }
                    
                setErrorList(result.error);
                if(result.validatedRows.length > 0 && Object.entries(result.error).length == 0){
                    setFormData((prevData) => ({
                        ...prevData,
                        validatedRows: result.validatedRows,
                    }));
                    onNext();
                }
                    
                setPopupOpen(false)
            } catch (error) {
                setPopupOpen(false)
                console.error(error)
            }
            // 
        }
    }


    return (
        <>
            <div className="content-page admin-new-addstaff-page">
                <div className="fullrow">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Import Digit Mapping</h1>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="addnew-button">
                                <Link to="/digit-mapping"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="edit-staff-data-form add-new-staff-form">
                            </div>
                            <form className="form-box" onSubmit={() => { }} ref={formRef}>
                                <div className="common-white-shadow-background">
                                    <div className="innerboxwidth">
                                        <div className='row'>
                                            <div className="col-12">
                                                <h3>Digit Mapping</h3>
                                            </div>
                                            <div className="col-12">
                                                {role <=2 &&  <label>Select Company</label>}
                                                {role <= 2 &&
                                                    <select name="cid" id="user_role" onChange={handleInputChange} className={validationErrors.cid ? 'invalid' : ''} >
                                                        <option value="">Select Company</option>
                                                        {company.map((comp) => (
                                                            <option key={comp.id} value={comp.id}>
                                                                {comp.cname}
                                                            </option>
                                                        ))}
                                                    </select>}
                                                
                                                {validationErrors.cid && <div className="error error-message">Please select a company.</div>}

                                            </div>

                                            <div className="col-12">
                                                <label>Digit Mapping type</label>
                                                <select name="mapping_type" id="mapping_type" onChange={handleInputChange} className={validationErrors.mapping_type ? 'invalid' : ''} >
                                                    <option value="">Select Type</option>
                                                    <option value="1">Standard Digit Mapping</option>
                                                    <option value="2">Offnet Digit Mapping</option>


                                                </select>
                                                {validationErrors.mapping_type && <div className="error error-message">Please select a digit mapping type.</div>}
                                            </div>


                                        </div>
                                    </div>

                                </div>

                                {(formData.cid !== '' && formData.mapping_type !== '0' && formData.mapping_type !== '') &&
                                    <div className="common-white-shadow-background setting-section">
                                        <div className="row">

                                            <h3>Upload File (Using Template)</h3>
                                            <div className="download-templte">
                                                {formData.mapping_type === '1' && <a className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/ImportDigitMappingStandard.xlsx`}>Download Template</a>}
                                                {formData.mapping_type === '2' && <a className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/ImportDigitMappingOffnet.xlsx`}>Download Template</a>}
                                                <p>Submissions must be in the format of this template</p>
                                            </div>
                                            <div className="file-upload-field">
                                                <div className="upload-btn-wrapper">
                                                    <input type="file" ref={fileInputRef} name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                                                </div>
                                            </div>
                                            {validationErrors.file && (
                                                <p className="error">Please choose a file.</p>
                                            )}
                                        </div>
                                    </div>}

                                    {(formData.cid !== '' && formData.mapping_type !== '0' && formData.mapping_type !== '') && 
                                    <div className="row footer-row">
                                    {/* <a href="#" className="Cancel">Cancel</a> */}
                                    <a href="#" className="next" onClick={handleNext}>Next</a>
                                </div>  }

                                {Object.entries(errorList).length > 0 && <div style={{'color':"red","paddingBottom":"30px"}} ref={errorListRef}>
                                    { Object.entries(errorList).length > 0 && <p>Please resolve below errors</p>}
                                    <table  className="tablebox search-result-table order-result-table">
                                        <tbody>
                                            {Object.entries(errorList).map(([key, value]) => (
                                                key !== 'statusCode' && key !== 'show' && (
                                              <tr key={key}>
                                                <td><strong>{key}:</strong></td>
                                                {console.log(value)}
                                                {Array.isArray(value) ? (
                                                  value.map((item, index) => (
                                                    <p key={index}>{item.trim()}</p>
                                                  ))
                                                ) : typeof value === 'string' ? (
                                                  <p>{value.trim()}</p>
                                                ) : null}
                                              </tr>
                                            )))}
                                        </tbody>
                                    </table></div>}        
                            </form>
                        </div></div>
                </div>
            </div>
        </>)
}

const Step2 = ({ onBack, onNext, token, formData, setFormData  }) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [updatedDMT, setUpdatedDMT] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [digitMappingList, setDigitMappingList] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if(formData.validatedRows.length > 0){
            getDigitMappingList();
        }
    }, [formData.validatedRows]);

    const getDigitMappingList = async () => {
        setPopupOpen(true);
       
        const aniNumberList = formData.validatedRows.filter(record => record.translation_type === 0).map(item => item.telephoneNumber);
        const dnisNumberList = formData.validatedRows.filter(record => record.translation_type === 1).map(item => item.telephoneNumber);
       
        const response = await axios.post(`${baseUrl}/get-digitmapping-tnnumber`, {aniNumberList : aniNumberList,dnisNumberList:dnisNumberList , company_id: formData.cid},{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
   
        const updatedArray = response.data[0].data.map(obj => ({ ...obj, ["status"]: 1 }));
        setDigitMappingList(response.data[0].data);
        // setSelectedRows(response.data[0].data);
        setUpdatedDMT(updatedArray);
        setPopupOpen(false);
    }
    const handleSwitchSelectAll = (e) => {
        if (selectAll) {
            const updatedArray = digitMappingList.map(obj => ({ ...obj, ["status"]: 0 }));
            setSelectedRows([]);
            setUpdatedDMT(updatedArray);
        } else {
            const updatedArray = digitMappingList.map(obj => ({ ...obj, ["status"]: 1 }));
            setSelectedRows(digitMappingList); // Select all rows
            setUpdatedDMT(updatedArray);
        }
        setSelectAll(!selectAll);
    };

    const handleToggleSelect = (index) => {
        const updatedSelectedRows = selectedRows.includes(digitMappingList[index])
            ? selectedRows.filter(item => item !== digitMappingList[index]) // Deselect
            : [...selectedRows, digitMappingList[index]]; // Select

        const updateArray = digitMappingList.map((obj) => {
            const existsInUpdated = updatedSelectedRows.some(updatedObj => updatedObj.alias === obj.alias);
            if(existsInUpdated){
                obj.status = 1;
            }else{
                obj.status = 0;
            }
            return obj;
        })
        setUpdatedDMT(updateArray);
        setSelectedRows(updatedSelectedRows);

    };

    const handleNext = async(e) => {
        e.preventDefault();
        const {name} = e.target;
        let importType; 
        if(name == "import"){
            importType = 1;
        }else if(name == "remove_import"){
            importType = 2;
        }else if(name == "remove_all_import"){
            importType = 3;
        }
        const combineData = {
            company_id : formData.cid,
            mapping_type :formData.mapping_type,
            import_type : importType,
            rowList : formData.validatedRows,
            updatedDMT : updatedDMT
        }
        console.log(combineData);
        setPopupOpen(true);
        try {
            const response = await axios.post(`${baseUrl}/digit-mapping/import`, combineData,{
                headers: {
                     Authorization: `Bearer ${token}`
                     
                }
            });
    
            const allArray = response.data;
            console.log(allArray); 
            navigate("/digit-mapping");
        } catch (error) {
            console.error(error)
        }
        setPopupOpen(false)
        
    }
    return(
        <>
        {popupOpen && (
             <Loader /> 
        ) }
        {/* <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div style={{ display: "flex" }}>
                <div className="contact-input order-con-title">Associated DMT List</div>
            </div>     
        </div> */}
        <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
        <div className="row footer-row">
            <div className="footer-row-left">
                <Link className="Cancel" to={"/number-management"}>Cancel</Link>                
            </div>
            <div className="footer-row-right">
                {/* <a href="#" className="Cancel">Cancel</a> */}
                
                {digitMappingList.length > 0 && <a href="#" name="remove_all_import" className={buttonDisabled ? 'next disabled-link newrow' : 'next newrow'} onClick={(e) => {handleNext(e)}} >Remove all & Import</a>}

                {selectedRows.length > 0 && <a href="#" name="remove_import" className={buttonDisabled ? 'next disabled-link newrow' : 'next newrow'} onClick={(e) => {handleNext(e)}} >Remove & Import</a>}
                <a href="#" name="import" className={buttonDisabled ? 'next disabled-link' : 'next'} onClick={(e) => {handleNext(e)}} >Import</a>
            </div>
        </div>
        </div>
        {digitMappingList.length > 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
                <div className="row">
                    <div className="order-con-title">Review Existing Associated DMT</div>  
                    
                </div>
                <table className="tablebox new-number-two-setup">
                    <thead>
                        <tr>
                            <th><input type="checkbox" className="newrow checkboxAll"  onChange={handleSwitchSelectAll} checked={selectAll} /></th>
                            <th>Alias</th>
                            <th>Origination ANI</th>
                            <th>Match Length</th>
                            <th>Origination DNIS</th>
                            <th>Match Length</th>
                            <th>Translated ANI</th>
                            <th>Translated DNIS</th>
                            <th>ANI Action</th>
                            <th>DNIS Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {digitMappingList.map((dmt, index) => (
                            <tr key={index}>
                                {/* <td>{selectedRows[0].alias}</td> */}
                            <td><input type="checkbox"  checked={selectedRows.includes(dmt)} onChange={() => handleToggleSelect(index)} /></td>
                            <td>{dmt.alias}</td>
                            <td>{dmt.origAni}</td>
                            <td>{dmt.origAniMinLength} - {dmt.origAniMaxLength}</td>
                            <td>{dmt.origDnis}</td>
                            <td>{dmt.origDnisMinLength} - {dmt.origDnisMaxLength}</td>
                            <td>{dmt.transAni}</td>
                            <td>{dmt.transDnis}</td>
                            <td>{dmt.aniAction}</td>
                            <td>{dmt.dnisAction}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>}
        {digitMappingList.length === 0 && <div className="common-white-shadow-background setting-section tableinput-section order-number-four-table">
            <div className="row">
                <div className="order-con-title">Review Existing Associated DMT</div>  
                
            </div>
            
            <div>No Record Found</div>
        </div>}
        
        </>
    );
}

const ImportDigitMapping = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState({
        cid: '',
        mapping_type: "0",
    });
    const [step, setStep] = useState(1);
   
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(!hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
 
    const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onNext={handleNext} compid={cid} role={role} token={token} formData={formData} setFormData={setFormData} />;
          case 2:
            return <Step2 onBack={handleBack} onNext={handleNext} token={token} formData={formData} setFormData={setFormData} />;
        //   case 5:
        //     return <Step5 data={formData} onFinish={handleFinish} />;
          default:
            return null;
        }
      };
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
                    {renderStep()}
                </div>
            </div>
        </div>
    )
}
 
export default ImportDigitMapping
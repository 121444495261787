const sansayList = [
    {name:"LA1",  ip:'8.26.78.38'},
    {name:"LA2",  ip:'8.41.59.138'},
    {name:"SLC1", ip:'8.37.87.138'},
    {name:"SLC2", ip:'8.37.87.158'},
    {name:"MN1",  ip:'8.38.41.138'},
    {name:"MN2",  ip:'8.38.41.158'},
    {name:"TOR1", ip:'8.26.79.38'},
];

export {sansayList};
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import { useNavigate, Link } from 'react-router-dom';
import Modal from 'react-modal';

const Step3 = ({ onBack, onNext, formData, setFormData }) => {
    const [showType, setShowType] = useState(formData.showType ?? 0);
    const [featureType, setFeatureType] = useState(0);
    const [dipFeatureType, setDipFeatureType] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [assignedCname, setAssignedCname] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [error, setError] = useState('');
    useEffect(() => {
        if(formData.showType == undefined){
            setFormData({ ...formData, showType: 0 });
        }
    }, [formData.showType]);
    const handleRadioChange = (e) => {
        setShowType(Number(e.target.value));
        setFormData({ ...formData, showType: Number(e.target.value) });
        if(Number(e.target.value) == 0){
            const modifiedArray = formData.tnNumberRouteList.map(obj => {
                // Destructure the object and exclude 'callerid' key
                const { callerId, ...rest } = obj;
                return rest; // Return the modified object without 'callerid'
            });
            setFormData({ ...formData, tnNumberRouteList: modifiedArray, showType: Number(e.target.value) });
        }
        
    };
    const handleDipFeatureRadioChange = (e) => {
        setDipFeatureType(Number(e.target.value));
        
    };
    const handleFeatureRadioChange = (e) => {
        setFeatureType(Number(e.target.value));
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!inputValue) {
            alert("Please enter cname")
            // Show a validation error, routing option not selected
            return;
        }
        if (selectedRows.length === 0) {
            alert("Please select at least one row")
            // Show a validation error, no rows selected
            return;
        }
        if(error){
            return;
        }   
        // setInputValue('');
        console.log(inputValue);
        const updatedAssignedCname = { ...assignedCname };
        selectedRows.forEach((telephoneNumber) => {
            updatedAssignedCname[telephoneNumber] = inputValue;
        });
        console.log(updatedAssignedCname);
        console.log(formData.tnNumberRouteList);

        const updatedtnNumberList = formData.tnNumberRouteList.map((obj) => {
            const telephoneNumber = obj.telephoneNumber;
            const callerIdName = updatedAssignedCname[telephoneNumber];

            if (callerIdName) {
              return {
                ...obj,
                callerId: { callingName: callerIdName },
              };
            } else {
              return obj;
            }
        });    
        setFormData({ ...formData, tnNumberRouteList: updatedtnNumberList });
        setAssignedCname(updatedAssignedCname);
    };

    const handleSelectAll = () => {        
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(formData.reserveNumberList.filter(item => formData.methodType == 0).map(item => item.telephoneNumber));
        }
        setSelectAll(!selectAll);                
    };

    const handleToggleSelect = ( phoneNumber) => {
        const updatedSelectedRows = selectedRows.includes(phoneNumber)
        ? selectedRows.filter((num) => num !== phoneNumber)
        : [...selectedRows, phoneNumber];
            
        setSelectedRows(updatedSelectedRows);
        
    };

    const handleCnameChange = (e) => {
        
        const inputValue = e.target.value;
    
        // Regular expression to check if the input is valid
        const isValidInput = /^[A-Za-z ]{0,15}$/.test(inputValue);
        setInputValue(inputValue);
        if (isValidInput) {
                    
          setError('');
        } else {
          setError('Invalid input. Only alphabetical characters and spaces allowed, up to 15 characters.');
        }
    };

    return(
   
    <>
        <div className="row">
            <div className="col-6">
                <div className="left-title">
                    <h1>Order New Number </h1>
                    {/* <small>(Order Summary Step 3)</small> */}
                </div>
            </div>
            <div className="col-6">
            </div>
        </div>
        <div className="common-white-shadow-background setting-section tableinput-section">
            <div className="row footer-row">
                <div className="footer-row-left">
                    <a href="#" onClick={onBack} className="Back"> <img src="images/back-arrow.png" />Back</a>
                </div>
                <div className="footer-row-right">
                <Link className="Cancel" to={"/number-management"}>Cancel</Link>
                    <a href="#" onClick={onNext} className="next">Next</a>
                </div>
            </div>
        </div>
        <div className="common-white-shadow-background setting-section">
            <div className="row">
                <div className="modification-txt">
                    <p>TELEPHONE NUMBER FEATURE ENTRY METHOD</p>
                </div>
                <div className="settingbox radio-standard-box">
                    <label for="skip"><input type="radio" id="skip" name="method_type" value={0} checked={formData.showType === 0} onChange={handleRadioChange} />
                        Skip</label>          
                    <label for="portal"><input type="radio" id="portal" name="method_type" value={1} checked={formData.showType === 1} onChange={handleRadioChange} />
                    CNAM</label>                      
                </div>
                {showType === 1 && 
                    <>
                    <div className='col-sm-6' style={{ marginTop: '14px' }}>
                      <input
                        type="text"   
                        value={inputValue}                         
                        onChange={handleCnameChange}                   
                        placeholder="Enter CNAM"
                        // style={{ textTransform: 'uppercase' }}
                      />
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                     
                    </div>
                   <div className='col-sm-6' style={{ marginTop: '11px' }}>
                         <input type="submit" className='yellow-button'  value="Apply"  onClick={handleSubmit} />
                    </div>
                    </>
                }
            </div>
        </div>
        <div className="common-white-shadow-background setting-section tableinput-section">
            <div className="row">
                <table className="tablebox new-number-two-setup">
                    <thead>
                        <tr>
                            {formData.methodType !== 2 && <th>Lata</th>}
                            {formData.methodType !== 2 && <th>Rate Center</th>}
                            {formData.methodType !== 2 && <th>State</th>}
                            <th>Tel Number</th>
                            {showType === 1 &&<th style={{ paddingTop: "50px" }}> Select Number <input type="checkbox" className="newrow checkboxAll" onClick={handleSelectAll} checked={selectAll}  /></th>}
                            {showType === 1 &&<th>Caller ID</th>}
                            <th>Routing Option</th>
                        </tr>
                    </thead>
                    <tbody>
                    {formData.reserveNumberList.map((item, index) => 
                         <tr key={index}>
                         {formData.methodType !== 2 && <td>{item.lata}</td>}
                         {formData.methodType !== 2 && <td>{item.rateCenterName}</td>}
                         {formData.methodType !== 2 && <td>{item.province}</td>}
                         <td>{item.telephoneNumber}</td>
                         {showType === 1 && formData.methodType !== 1 && <td><input type="checkbox" name="" checked={selectedRows.includes(item.telephoneNumber)}
                   onChange={() => handleToggleSelect(item.telephoneNumber)} /></td>}
                   {showType === 1 && formData.methodType == 1 && <td></td>}
                   {showType === 1 && <td><p className='green-text'>
      {formData.tnNumberRouteList &&
      formData.tnNumberRouteList[index] &&
      formData.tnNumberRouteList[index].callerId &&
      formData.tnNumberRouteList[index].callerId.callingName
        ? formData.tnNumberRouteList[index].callerId.callingName
        : assignedCname[item.telephoneNumber] || ''}
    </p></td>}
                         <td>
                         {/* <p className="green-text">{formData.tnNumberRouteList && formData.tnNumberRouteList[index]
                                ? formData.tnNumberRouteList[index].routeOption  || ''
                                :  ''}</p> */}
                                {formData.routeResults && formData.routeResults.length > 0 ? <p className='green-text'>{formData.routeResults[index]}</p> :  <p className="green-text">{formData.tnNumberRouteList && formData.tnNumberRouteList[index]
                                ?  (formData.tnNumberRouteList.find(items => items.telephoneNumber === item.telephoneNumber) || {}).routeOption
                                :  ''}</p>}
                               
                            </td>
                         {/* <td><p className="green-text">{item.routeOption}</p></td> */}
                     </tr>)}
                        
                    </tbody>
                </table>
            </div>
        </div>

        
      
    </>
)};

export default Step3;
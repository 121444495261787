
import React, { useState, useEffect } from 'react';
import TrunkPopup from './TrunkPopup';

const DisplayOutboundTable = ({ carrierTrunk, values, currentTrunk, removeFromList, handleOutboundTrunkInputChange, handleOutboundCheckboxChange, outboundTrunkData }) => {
    console.log(carrierTrunk);
  const [invalidIds, setInvalidIds] = useState([]);
  const [selectedTrunkIds, setSelectedTrunkIds] = useState([]);
  const [initialInputValues, setInitialInputValues] = useState([]);
  
  useEffect(() => {
    if(outboundTrunkData.length > 0){
      // console.log(outboundTrunkData);
      const initialSelectedTrunkIds = outboundTrunkData.map((item) => item.name);
   setSelectedTrunkIds(initialSelectedTrunkIds);

   const initialInputValues = outboundTrunkData.map((item) => item.value);
   console.log(outboundTrunkData);
   setInitialInputValues(initialInputValues);
    }else{
      setSelectedTrunkIds([]);
      setInitialInputValues([]);
    }
   
   
    // Filter out objects with isValid set to false and extract their IDs
    // const filteredInvalidIds = values.filter(value => !value.isValid).map(value => value.trunkId);
    const filteredInvalidIds = values
          .filter((value) => !value.isValid && currentTrunk.includes(value.trunkId.toString()))
          .map((value) => value.msg);
    // console.log(filteredInvalidIds);
    // Update the list of invalid IDs
    setInvalidIds(filteredInvalidIds);
  }, [values, outboundTrunkData]);

  const closePopup = () => {
    setInvalidIds([]);
  };
console.log(initialInputValues);
  const handleSelectChange = (e, index) => {
    // Update the selectedTrunkId state when the select dropdown changes
	const newSelectedTrunkIds = [...selectedTrunkIds]; // Create a copy of the array
    newSelectedTrunkIds[index] = e.target.value; // Update the value at the specific index
    setSelectedTrunkIds(newSelectedTrunkIds);
	// console.log(e.target.value);
	// console.log(e.target.parentElement.parentElement);
	handleOutboundCheckboxChange(e, {name:e.target.value,  value:e.target.parentElement.parentElement.querySelector('input[type="text"]').value}, 0, index)
  };

  return (
    <>
    {invalidIds.length > 0 && <div className="error error-message"><p>Invalid TID's: {invalidIds.join(', ')}</p></div>}
    <div className="tablebox">
      <div className="fullwidth-table track-table-body trunk-table staff-listing-admin-table-body">
        <table>
          <thead>
            <tr>
              <th>TID</th>
              <th>Company Name</th>
              <th>Name</th>
              {/* <th>Carrier Trunk</th> */}
              <th>Customer Friendly Name</th>
              <th></th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {values.map((value, index) => (
              <tr key={index}>
                {value.isValid && (
                  <>
                    <td>{value.trunkId}</td>
                    <td>{value.companyName}</td>
                    <td>{value.name}</td>
                    {/* <td>
                      	<select required
                           
						   onChange={(e) => { handleSelectChange(e, index); }}
						   value={selectedTrunkIds[index] || ''}>
                          	<option value="">Select Trunk</option>
							{carrierTrunk.map((trunk, index) => (
                            	<option key={index} value={trunk.name}>
                            	  	{trunk.name}
                                </option>
                            ))}                              
                        </select>
					</td> */}
                    <td>
                        <input 
                          type="text"
                          required
						  style={{ width: '100%' }} 
						  value={initialInputValues[index] || ''}
                          onChange={(e) =>
                            handleOutboundTrunkInputChange(
                              e,
							  selectedTrunkIds[index],
                index
                              
                            )
                          }
                        />
                      </td>                           
                    <td>
                      <a onClick={() => removeFromList(index)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`}
                          alt="Delete"
                          title="Use this option to remove TID"
                        />
                      </a>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {/* {invalidIds.length > 0 && (
          <TrunkPopup isOpen={true} onClose={closePopup} trunk={invalidIds} />
        )} */}
      </div>
    </div>
    </>
  );
};

export default DisplayOutboundTable;


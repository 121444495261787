/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { saveAs } from 'file-saver';
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");
 
const DigitMappingList = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [digitMapping, setDigitMapping] = useState([]);
    // const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [cid, setCid] = useState('');
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [company, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [selectedUserType, setSelectedUserType] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [checkDigitMapping, setCheckDigitMapping] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
        // getDigitMapping();
    }, []);
    useEffect(() => {
        if(access.length > 0 && token && checkDigitMapping){
            checkAccess(access, token);
        }
        
    }, [access, token, checkDigitMapping]);
    useEffect(() => {
        if(cid){
            getCustomerDigitMappingAccess();
        }
    }, [cid]);
    useEffect(() => {
        getDigitMapping(page,selectedCompany, selectedUserType, searchValue);
    }, [page]);
   
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getCustomerDigitMappingAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
            setCheckDigitMapping(response.data.digit_mapping_switch === 1 ? true : false);

        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };
    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }
    const handleComapnyChange = (e) => {
        setSelectedCompany(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getDigitMapping(page,e.target.value, selectedUserType); // Reset page to 1 when filter changes
    };

    const handleUserTypeChange = (e) => {
        setSelectedUserType(e.target.value);
        // setPage(1);
        // console.log(e.target.value);
        // getDigitMapping(page,selectedCompany, e.target.value, ''); // Reset page to 1 when filter changes
    };
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };
    const handleSearchSubmit = () => {
        setPage(1);
        getDigitMapping(1, selectedCompany, selectedUserType, searchValue);
    };

    const clearFilter = () => {
        setSelectedUserType(0);
        setSelectedCompany(0);
        setSearchValue('');
        setPage(1);
        getDigitMapping(page,0, 0, '');
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {        
        if(!hasPermission('ordernewnumber') || !checkDigitMapping){
            navigate('/dashboard');
        }
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getDigitMapping = async (page, cid,usertype, search) => {
        // console.log(page);
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/digit-mapping?page=${page}&limit=${20}&company=${cid}&userType=${usertype}&search=${search}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        console.log(response.data);
        setDigitMapping(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data);
        setCompany(response.data);
    }

    const deleteMapping = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to delete this digit mapping?");
    if (shouldDelete) {
     
    
        try {
            
            await axios.delete(`${baseUrl}/digit-mapping/${id}`,{headers: {
                Authorization: `Bearer ${token}`
            }});
            window.location.reload();
        } catch (error) {
            console.log(error);
            // if (error.response) {
            //     setMsg(error.response.data.msg);
            // }
        }
    }
        
    };

    const handleExportCSV = async(e) => {
        e.preventDefault();
 
        const headerRow = ["Tel Number","Company_name","Alias","Origination ANI","Match Length","Origination DNIS", `Match Length`,"Translated ANI","Translated DNIS","ANI Action","DNIS Action"];

    //     // Create a CSV string from the mapped data array
    //     console.log(inventory);
        const csv = [headerRow].concat(
            digitMapping.map(row => {

                const ani_length = row.ani_min_length +"-"+ row.ani_max_length;
                const dnis_length = row.dnis_min_length +"-"+ row.dnis_max_length;
                const aniAction = row.ani_action == 0 ? "all" : (row.ani_action == 2 ? "passthrough" : (row.ani_action == 3 ? "prepend" : "match"));
                const dnisAction = row.dnis_action == 0 ? "all" : (row.dnis_action == 2 ? "passthrough" : (row.dnis_action == 3 ? "noLNP" : (row.dnis_action == 4 ? "prepend" : "match")));
    //         // Create an array of values, including the modified status
            return [row.number, row.cname,row.alias, row.original_ani, ani_length,row.original_dnis,dnis_length,row.translated_ani,row.translated_dnis,aniAction, dnisAction].join(',');
          })).join('\n');
          

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        saveAs(blob, 'digit_mapping.csv');
      };
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page ">
                <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>Digit Mapping</h1>
                        </div>
                    </div>
                   
                    <div className="col-4 edit-inventory-btn">
                            <div className="addnew-button">
                               <Link to="/digit-mapping/add"> Add</Link>
                               <a className="newrow"  onClick={handleExportCSV} href="#">Export</a>
                                {/* <Link className='newrow' to="/digit-mapping/import">Import</Link> */}
                            </div>
                        </div> 
                    
                </div>
                <div className='row'>
                    <div className="col-md-12 order-filter transection-filter">
                        <div className="boxinput">
                            
                        {role <=2 && <><select className="company-name form-control" name="company" value={selectedCompany} onChange={handleComapnyChange}>
                                    <option value={0} >Company</option>
                                    {company.map((comp, index) => (
                                        <option key={index} value={comp.id}>
                                          {comp.cname}
                                        </option>
                                    ))}
                                </select></>}
                                <input style={{marginRight:'10px'}} className="search-leave satff-listing-page-search" type="text" name="search" placeholder="Search tn Number" value={searchValue} onChange={handleSearchChange} /> 
                                 <button  className="search-btn " onClick={handleSearchSubmit}>Search</button>
                                 { (selectedCompany != 0 || searchValue ) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            
                           
                        </div>
                    </div>
                </div>
                <div className="fullrow">
                    
                    
                    <div className="row">
                        <div className="col-12">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body dmt-table">
                                <table>
                                    <thead>
                                    <tr>
                                        
                                        <th>Alias</th>
                                        <th>Description</th>
                                        <th>Origination ANI</th>
                                        <th>Match Length</th>
                                        <th>Origination DNIS</th>
                                        <th>Match Length</th>
                                        <th>Translated ANI</th>
                                        <th>Translated DNIS</th>
                                        <th>ANI Action</th>
                                        <th>DNIS Action</th>
                                        {/* <th>Status</th> */}
                                        <th></th>  
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {digitMapping.map((mapping, index) => (
                                        <tr key={mapping.id}>
                                            
                                            <td>{mapping.alias}</td>
                                            <td>{mapping.description}</td>
                                            <td>{mapping.original_ani}</td>
                                            <td>{mapping.ani_min_length} - {mapping.ani_max_length}</td>
                                            <td>{mapping.original_dnis}</td>
                                            <td>{mapping.dnis_min_length} - {mapping.dnis_max_length}</td>
                                            <td>{mapping.translated_ani}</td>
                                            <td>{mapping.translated_dnis}</td>
                                            <td>{mapping.ani_action == 0 ? "all" : (mapping.ani_action == 2 ? "passthrough" : (mapping.ani_action == 3 ? "prepend" : "match"))}</td>
                                            <td>{mapping.dnis_action == 0 ? "all" : (mapping.dnis_action == 2 ? "passthrough" : (mapping.dnis_action == 3 ? "noLNP" : (mapping.dnis_action == 4 ? "prepend" : "match")))}</td>
                                            {/* <td>{user.role === 1 ? 'Super Admin' : user.role === 2 ? 'Support User' : user.role === 3 ? 'Reseller Admin' : user.role === 4 ? 'Customer admin' :  user.role === 5 ? 'Customer user' : 'Reseller support'}</td> */}
                                            
                                            {/* <td>{user.is_active === 1 ? <a href="#" className="true">True</a> : <a href="#" className="false">False</a> }</td> */}
                                            <td>
                                                <ul>
                                                    { <li><Link to={`/digit-mapping/edit/${mapping.id}`}><img src={`${process.env.PUBLIC_URL}/images/bx-edit.png`} alt="Edit" title="Use this option to edit mapping" /></Link></li>}
                                                    {<li><a  onClick={() => deleteMapping(mapping.id)}><img src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`} alt="Delete" title="Use this option to delete mapping"/></a></li>  } 
                                                    {/* {(mapping.status === 0 && hasPermission('createuser')) && <li><a  onClick={() => activateUser(mapping.id)}><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Active" title="Use this option to enable user" /></a></li>  }                                                    */}
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/* <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>User Lisiting</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
 
                </tbody>
            </table>
                    
                </div> */}
            </div>
        </div>
    )
}
 
export default DigitMappingList
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "./Navbar";
import Header from "./Header";
import baseUrl from "./BaseUrl";
import Loader from "./Loader";
import { useNavigate, Link } from 'react-router-dom';
 
const Dashboard = () => {
    const [name, setName] = useState('');
    const [cid, setCid] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [pbxCheck, setPbxCheck] = useState(false);
    const [role, setRole] = useState('');
    const [pbxUrl, setPbxUrl] = useState('');
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [tnsAccess, setTNSAccess] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
        checkCouldPbx();
    }, []);

    useEffect(() => {
        if(cid){
            getCustomerTNSAccess();
        }
    }, [cid]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            setPopupOpen(true);
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            setPopupOpen(false);
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
            setPopupOpen(false);
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    const hasPermission = (permission) => {
        
        return access.some((item) => item.access === permission);
      };

    const getCustomerTNSAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
           
            setTNSAccess(response.data.api_enable_switch === 1 ? true : false);

        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const checkCouldPbx = async () => {
        const response = await axiosJWT.get(`${baseUrl}/check-cloud-pbx`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        // console.log(response.data.isPbxCheck);
        const userSwitch = (response.data.isPbxCheck === true) ? true : false;
        // console.log(response.data.isPbxCheck);
        setPbxCheck(userSwitch);
        let url = response.data.url;
        console.log(url);
        if (url != null && !url.startsWith("http://") && !url.startsWith("https://")) {
            url = `https://${url}`;
        }else{
            url = `${url}`;
        }
        console.log(url);
        setPbxUrl(url);
    }

//     const handleOpenNewTab = async () => {
        
    
//           const res = await axios.post('https://bbdpbx.bbdtel.com/admin/config.php', {
//             username: "creosolutions",
//             password: 'DevView987',
//             },{
//                 Cookie: 'lang=en_US; PHPSESSID=fqcv2vjg5r4bpa1v2g52gatpo5' 
           
//         });
        
        
          
//           console.log(res);
// //           localStorage.setItem("type", res.data.user["admin"]);
// // localStorage.setItem("token", res.data.token);
// // localStorage.setItem("access", res.data.access["type"]);
// // localStorage.setItem("permission", res.data.permission["details"]["permission"]);
// window.location.href = 'https://bbdpbx.bbdtel.com/admin/config.php';
    
//           // Open a new browser tab and populate it with the HTML content
//         //   const newTab = window.open();
//         //   newTab.document.open();
//         //   newTab.document.write(response.data); 
//     }
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Dashboard</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <div className="row numberboxes">
                         <div className="col-md-4">
                            <Link to="/number-management">
                            <div className="dashboard-box" id="inventory">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/bxs-phone.png`} alt="" />
                                </div>
                                <h3>Number Management</h3>
                                
                            </div>
                            </Link>
                        </div>
                        {(( role !== 4 && role !== 5 && role !== 3) && hasPermission('viewuser'))&& <div className="col-md-4">
                            <Link to="/user-company/list">
                            <div className="dashboard-box" id="number">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/dbxs-user.png`} alt="" />
                                </div>
                                <h3>Customer Management</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {role !== 5 && hasPermission('viewuser') && <div className="col-md-4">
                            <Link to="/user">
                            <div className="dashboard-box" id="userbox">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/dbxs-user.png`} alt="" />
                                </div>
                                <h3>User Management</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {( (role !== 3 && role !== 4 && role !== 5 ) && hasPermission('viewuser'))&&<div className="col-md-4">
                            <Link to="/user-reseller/list">
                            <div className="dashboard-box" id="bbdbox">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/dbxs-user.png`} alt="" />
                                </div>
                                <h3>Reseller Management</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {/* {tnsAccess && <div className="col-md-4">
                            <Link to="/dialed-number/list">
                            <div className="dashboard-box" id="importdid">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/bxs-file-import.png`} alt="" />
                                </div>
                                <h3>Telephone Number Status</h3>
                            </div>
                            </Link>
                        </div>} */}
                        {pbxCheck && <div className="col-md-4">
                        <Link to={pbxUrl} target="_blank" rel="noopener noreferrer">
                            <div  className="dashboard-box"  id="viewtrans">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/bxs-cloud.png`} alt="" />
                                </div>
                                <h3>BBD Cloud PBX</h3>
                                
                            </div>
                            </Link></div>}
                        {role < 2 && <div className="col-md-4">
                            <Link to="/settings">
                            <div className="dashboard-box" id="number">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/dbxs-user.png`} alt="" />
                                </div>
                                <h3>Settings</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                    </div>
                </div>
            </div>
            {/* <h1>Welcome Back: {name}</h1>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
 
                </tbody>
            </table> */}
        </div>
    )
}
 
export default Dashboard
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
 
const ApplicationSettings = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [newApplication, setNewApplication] = useState({ name: '', url: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [application, setApplication] = useState([]);
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);
    useEffect(() => {
        getApplicationList(page);
    }, [page]);
    useEffect(() => {
        if(role >= 2){
            checkAccess();
        }
        
    }, [role]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const handlePageChange  = (pageNumber) => {
        setPage(pageNumber);
    }
   

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        
        }
    }
   

    const checkAccess = async () => {
        navigate('/dashboard');
    }
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getApplicationList = async (page) => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/application-settings?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setPopupOpen(false);
        setApplication(response.data.data);
        setTotalItem(response.data.totalItem);
    }

  
   
    const handleAddApplication = () => {
        setNewApplication({ name: '', url: '' });
        setIsEditing(false);
        setIsAdd(!isAdd);
        setEditIndex(null);
        setErrors({})
    };

    const handleInputChange = (e) => {
        setNewApplication({ ...newApplication, [e.target.name]: e.target.value });
    };
    const validate = () => {
        const errors = {};
        if (!newApplication.name.trim()) {
          errors.name = 'Name is required';
        }
        if (!newApplication.url || !newApplication.url.trim()) {
            errors.url = 'URL is required';
        } else if (!newApplication.url.trim().startsWith('https://')) {
            errors.url = 'URL must start with https://';
        }
    
        return errors;
    };
    const handleSave = async () => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        let updateObj = newApplication;
        if (isEditing) {
            // Update carrier
            
            const { id, name, url } = newApplication;
            updateObj = { id, name, url };
            
        }
        try {
            setButtonDisabled(true);
            setPopupOpen(true);
            await axios.post(`${baseUrl}/application-settings`,updateObj,{headers: {
                Authorization: `Bearer ${token}`
            }});
            setPopupOpen(false);
            window.location.reload();
        } catch (error) {
            setButtonDisabled(false);
            setPopupOpen(false);
           
        }
        setErrors({});
    }
    const handleEdit = (index) => {
        const data = application[index];
        setNewApplication(data);
        setIsEditing(true);
        setErrors({});
        setEditIndex(index);
        setIsAdd(false);
        setErrors({});
    };

    const disableCarrier = async (id) => {
        const shouldDelete = window.confirm("Are you sure you want to disable this carrier?");
        if (shouldDelete) {
            try {
                await axios.delete(`${baseUrl}/application-settings/${id}`,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                window.location.reload();
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="content-page admin-attendance-page">
                <div className="row">
                    <div className="col-6">
                        <div className="left-title">
                            <h1>Application Listing</h1>
                        </div>
                    </div>
                    <div className="col-6 inventory-btn">
                        <div className="addnew-button">
                            <Link to="/settings"><span>&larr;</span> Back</Link>
                            <a className="newrow" onClick={handleAddApplication}><span>+</span> Add Application</a>
                        </div>
                    </div>
                </div>
                <div className="fullrow">
                    <div className="row admin-staff-list-toppart">
                        <div className="col-md-8">
                            <div className="boxinput">
                            
                            
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                        { isAdd && 
                            <div className="common-white-shadow-background setting-section">
                                <div className="row">
                                    <div className="col-sm-4" >
                                        {/* <label>Name</label> */}
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={newApplication.name}
                                            onChange={handleInputChange}
                                        />
                                        {errors.name && <div className="error error-message">Please enter Name.</div>}
                                    </div>
                                    <div className="col-sm-4" >
                                        {/* <label>Url</label> */}
                                        <input
                                            type="url"
                                            name="url"
                                            placeholder="Url"
                                            value={newApplication.url}
                                            onChange={handleInputChange}
                                        />
                                        {errors.url && <div className="error error-message">{errors.url}</div>}
                                    </div>
                                    <div className="col-sm-2 boxinput company-search" >
                                        <a href="#" className={buttonDisabled ? 'disabled-link search-btn clear-btn' : 'search-btn clear-btn'}  onClick={handleSave} >Save</a>
                                    </div>
                                </div>
                            </div>}
            
        
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                <table>
                                    <thead>
                                    <tr>
                                        
                                        <th>Name</th>
                                        <th>Url</th>
                                        <th>Active</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {application.map((item, index) => (
                                        <tr key={item.id}>
                                            
                                            <td>
                                            {editIndex === index ? 
                                                <>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Name"
                                                        value={newApplication.name}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.name && <div className="error error-message">Please enter Name.</div>}
                                                </> : 
                                                item.name
                                            }</td>
                                            <td>
                                            {editIndex === index ? 
                                                <>
                                                    <input
                                                        type="url"
                                                        name="url"
                                                        placeholder="Url"
                                                        value={newApplication.url}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.url && <div className="error error-message">{errors.url}</div>}
                                                </>: item.url
                                            }
                                            </td>
                                            
                                            <td>
                                            {editIndex === index && <a href="#" className={buttonDisabled ? 'disabled-link' : ''}  onClick={handleSave} ><img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Active" title="Use this option to enable customer" /></a>}
                                                {item.status == 1 && <a onClick={() => handleEdit(index)}><img src={`${process.env.PUBLIC_URL}/images/bx-edit.png`} alt="Edit" title="Use this option to edit user" /></a>}
                                                <a  onClick={() => disableCarrier(item.id)}><img src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`} alt="Active" title="Use this option to disable user" /></a>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {/* <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>User Lisiting</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
 
                </tbody>
            </table>
                    
                </div> */}
            </div>
        </div>
    )
}
 
export default ApplicationSettings

import React, { useState, useEffect } from 'react';
import TrunkPopup from './TrunkPopup';

const DisplayTable = ({ carrierTrunk, values, currentTrunk, removeFromList, handleTrunkInputChange, handleCheckboxChange,handleBandwidthDidCheckboxChange, handleBandwidthTollFreeCheckboxChange, handleDidAccessCheckboxChange, handleTollFreeAccessCheckboxChange, trunkData, bandwdithDidData, bandwdithTollFreeData, selectDidAccessData, selectTollFreeAccessData }) => {
  const [invalidIds, setInvalidIds] = useState([]);
  const [selectedTrunkIds, setSelectedTrunkIds] = useState([]);
  const [selectedBandwidthDids, setSelectedBandwidthDids] = useState([]);
  const [selectedBandwidthTollFree, setSelectedBandwidthTollFree] = useState([]);
  const [selectDidAccess, setSelectDidAccess] = useState([]);
  const [selectTollFreeAccess, setSelectTollFreeAccess] = useState([]);
  const [initialInputValues, setInitialInputValues] = useState([]);
  
  useEffect(() => {
    if(trunkData.length > 0){
      
      const initialSelectedTrunkIds = trunkData.map((item) => item.name);
   setSelectedTrunkIds(initialSelectedTrunkIds);

   const initialInputValues = trunkData.map((item) => item.value);
  
   setInitialInputValues(initialInputValues);
    }else{
      setSelectedTrunkIds([]);
      setInitialInputValues([]);
    }
   
   
    // Filter out objects with isValid set to false and extract their IDs
    // const filteredInvalidIds = values.filter(value => !value.isValid).map(value => value.trunkId);
    const filteredInvalidIds = values
          .filter((value) => !value.isValid && currentTrunk.includes(value.trunkId.toString()))
          .map((value) => value.msg);
    console.log(filteredInvalidIds);
    // Update the list of invalid IDs
    setInvalidIds(filteredInvalidIds);
  }, [values, trunkData]);
  useEffect(() => {
    if(bandwdithDidData.length > 0){
      
      // const initialSelectedTrunkIds = bandwdithDidData.map((item) => item.name);
      const initialSelectedTrunkIds = bandwdithDidData.map((item) => {
        if (item == undefined) {
            return {"name": "", "value": ""};
        } else {
            return item.name;
        }
      });
      setSelectedBandwidthDids(initialSelectedTrunkIds);
    }else{
      setSelectedBandwidthDids([]);
    }
  }, [bandwdithDidData]);

  useEffect(() => {
    if(bandwdithTollFreeData.length > 0){
      
      const initialSelectedTrunkIds = bandwdithTollFreeData.map((item) => {
        if (item == undefined) {
            return {"name": "", "value": ""};
        } else {
            return item.name;
        }
      });
      setSelectedBandwidthTollFree(initialSelectedTrunkIds);
    }else{
      setSelectedBandwidthTollFree([]);
    }
  }, [bandwdithTollFreeData]);

  useEffect(() => {
    if(selectDidAccessData.length > 0){
      const initialSelectedDid = selectDidAccessData.map((item) => {
        if (item == undefined) {
            return {"name": "", "value": ""};
        } else {
            return item.name == 1 ? true : false;
        }
      });
      setSelectDidAccess(initialSelectedDid);
    }else{
      setSelectDidAccess([]);
    }
  }, [selectDidAccessData]);

  useEffect(() => {
    if(selectTollFreeAccessData.length > 0){
      const initialSelectedDid = selectTollFreeAccessData.map((item) => {
        if (item == undefined) {
            return {"name": "", "value": ""};
        } else {
            return item.name == 1 ? true : false;
        }
      });
      setSelectTollFreeAccess(initialSelectedDid);
    }else{
      setSelectTollFreeAccess([]);
    }
  }, [selectTollFreeAccessData]);

  const closePopup = () => {
    setInvalidIds([]);
  };
console.log(initialInputValues);
  const handleSelectChange = (e, index) => {
    
	  const newSelectedTrunkIds = [...selectedTrunkIds]; // Create a copy of the array
    newSelectedTrunkIds[index] = e.target.value; // Update the value at the specific index
    setSelectedTrunkIds(newSelectedTrunkIds);

	handleCheckboxChange(e, {name:e.target.value,  value:e.target.parentElement.parentElement.querySelector('input[type="text"]').value}, 0, index)
  };

  const handleBandwidthDidChange = (e, index) => {
    
	  const newSelectedTrunkIds = [...selectedTrunkIds]; // Create a copy of the array
    newSelectedTrunkIds[index] = e.target.value; // Update the value at the specific index
    setSelectedBandwidthDids(newSelectedTrunkIds);

    handleBandwidthDidCheckboxChange(e, {name:e.target.value,  value:e.target.parentElement.parentElement.querySelector('input[type="text"]').value}, 0, index)
  };

  const handleBandwidthTollFreeChange = (e, index) => {
    
	  const newSelectedTrunkIds = [...selectedTrunkIds]; // Create a copy of the array
    newSelectedTrunkIds[index] = e.target.value; // Update the value at the specific index
    setSelectedBandwidthTollFree(newSelectedTrunkIds);

    handleBandwidthTollFreeCheckboxChange(e, {name:e.target.value,  value:e.target.parentElement.parentElement.querySelector('input[type="text"]').value}, 0, index)
  };

  const handleDidAccess = (e, index) => {
    const checked = e.target.checked == true ? 1 : 2;
    const newSelectedTrunkIds = [...selectedTrunkIds];
    newSelectedTrunkIds[index] = checked;
    console.log(newSelectedTrunkIds, "tes");

    setSelectDidAccess(newSelectedTrunkIds);
    handleDidAccessCheckboxChange(e, {name: checked, value:e.target.parentElement.parentElement.querySelector('input[type="text"]').value}, 0, index)
  }

  const handleTollFreeAccess = (e, index) => {
    const checked = e.target.checked == true ? 1 : 2;
    const newSelectedTrunkIds = [...selectedTrunkIds];
    newSelectedTrunkIds[index] = checked;
    console.log(newSelectedTrunkIds, "tes");

    setSelectTollFreeAccess(newSelectedTrunkIds);
    handleTollFreeAccessCheckboxChange(e, {name: checked, value:e.target.parentElement.parentElement.querySelector('input[type="text"]').value}, 0, index)
  }

  return (
    <>
    {invalidIds.length > 0 && <div className="error error-message"><p>Invalid TID's: {invalidIds.join(', ')}</p></div>}
    <div className="tablebox">
      <div className="fullwidth-table track-table-body trunk-table staff-listing-admin-table-body">
        <table>
          <thead>
            <tr>
              <th>TID</th>
              <th>Company Name</th>
              <th>Name</th>
              <th>Inteliquent Carrier Trunk</th>
              <th>Bandwidth Carrier Trunk</th>
              <th>Bandwidth Toll Free Trunk</th>
              <th>DID</th>
              <th>Toll Free</th>
              <th>Customer Friendly Name</th>
              <th></th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {values.map((value, index) => (
              <tr key={index}>
                {value.isValid && (
                  <>
                    <td>{value.trunkId}</td>
                    <td>{value.companyName}</td>
                    <td>{value.name}</td>
                    <td>
                      	<select required onChange={(e) => { handleSelectChange(e, index); }} value={selectedTrunkIds[index] || ''}>
                          <option value="">Select Trunk</option>
							              {carrierTrunk.map((trunk, index) => (
                            	<option key={index} value={trunk.name}>
                            	  	{trunk.name}
                                </option>
                            ))}                              
                        </select>
					          </td>
                    <td>
                      <select required onChange={(e) => { handleBandwidthDidChange(e, index); }} value={selectedBandwidthDids[index] || ''}>
                          <option value="">Select Trunk</option>
                          {/* <option value="887106">Creo Test Location</option> */}
                          <option value="918750">BBD LA1 DID</option>
                          <option value="918754">BBD LA2 DID</option>
                          <option value="918755">BBD MIN1 DID</option>
                          <option value="918757">BBD MIN2 DID</option>
                          <option value="918761">BBD SLC1 DID</option>
                          <option value="918762">BBD SLC2 DID</option>
                          <option value="918766">BBD TOR DID</option>
                      </select>
                    </td>
                    <td>
                      <select required onChange={(e) => { handleBandwidthTollFreeChange(e, index); }} value={selectedBandwidthTollFree[index] || ''}>
                          <option value="">Select Trunk</option>
                          {/* <option value="887106">Creo Test Location</option> */}
                          <option value="543104">BBD LA1</option>
                          <option value="543105">BBD LA2</option>
                          <option value="543108">BBD MIN1</option>
                          <option value="543109">BBD MIN2</option>
                          <option value="543106">BBD SLC1</option>
                          <option value="543107">BBD SLC2</option>
                          <option value="543111">BBD TOR</option>
                      </select>
                    </td>
                    <td><input type='checkbox' name="did" onChange={(e) => { handleDidAccess(e, index); }}  checked={selectDidAccess[index] || false}  /></td>
                    <td><input type='checkbox' name="toll_free" onChange={(e) => {handleTollFreeAccess(e, index);}} checked={selectTollFreeAccess[index] || false} /></td>
                    <td>
                        <input 
                          type="text"
                          required
						  style={{ width: '100%' }} 
						  value={initialInputValues[index] || ''}
                          onChange={(e) =>
                            handleTrunkInputChange(
                              e,
							  selectedTrunkIds[index],
                index
                              
                            )
                          }
                        />
                      </td>                           
                    <td>
                      <a onClick={() => removeFromList(index)}>
                        <img
                          src={`${process.env.PUBLIC_URL}/images/bx-x-circle.png`}
                          alt="Delete"
                          title="Use this option to remove TID"
                        />
                      </a>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {/* {invalidIds.length > 0 && (
          <TrunkPopup isOpen={true} onClose={closePopup} trunk={invalidIds} />
        )} */}
      </div>
    </div>
    </>
  );
};

export default DisplayTable;

